import { render, staticRenderFns } from "./FooterRuDomain.vue?vue&type=template&id=6cbbead4&scoped=true&lang=pug"
import script from "./FooterRuDomain.vue?vue&type=script&lang=js"
export * from "./FooterRuDomain.vue?vue&type=script&lang=js"
import style0 from "./FooterRuDomain.vue?vue&type=style&index=0&id=6cbbead4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cbbead4",
  null
  
)

export default component.exports