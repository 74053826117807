<template lang="pug">
	.footer-ru
		.footer-ru__top
			.footer-ru__nav.footer-ru__first-col
				a.footer-ru__logo(href="/")
					img.footer-ru__logo-img(src="/assets/img/landingNew/footer/logo.svg" alt="Paywall")

				.footer-ru__socials(v-if="socialsRu && islocaleRuSystemAndIp")
					a.footer-ru__socials-link(v-for="(social, i) in socialsRu" :key="`social-${i}`" :href="social.href" target="_blank")
						img.footer-ru__socials-image(:src="social.src" :alt="social.name")

			nav.footer-ru__nav.footer-ru__menu
				span.d-block.footer-ru__nav-link.cursor-pointer(@click="goToMonetization") {{ $t('landingPage.header.navList[0]') }}
				span.d-block.footer-ru__nav-link.cursor-pointer(@click="goToNeurobot") {{ $t('landingPage.header.navList[1]') }}
				a.d-block.footer-ru__nav-link(v-if="islocaleRuSystemAndIp" :href="`https://paywall.pw${$t('contacts.blog')}`" target="_blank") {{ $t('landingPage.header.navList[2]') }}
				a.d-block.footer-ru__nav-link(v-if="islocaleRuSystemAndIp" :href="$t('contacts.knowledge_base')" target="_blank") {{ $t('landingPage.header.navList[3]') }}
				a.d-block.footer-ru__nav-link(href="/subscriber" target="_blank") {{ $t('landingPage.header.navList[4]') }}
				span.d-block.footer-ru__nav-link.cursor-pointer(@click="goToAboutUs") {{ $t('landingPage.header.navList[5]') }}

			.footer-ru__nav.d-none.d-lg-block.footer-ru__details
				span.d-block.footer-ru__txt.footer-ru__title {{ $t('bankDetails.title') }}
				span.color-grey.footer-ru__txt(v-html="$t('bankDetails.desc')")

			.footer-ru__nav.footer-ru__contacts
				span.d-block.footer-ru__txt.footer-ru__title {{ $t('contacts.title') }}
				div
					span.color-grey.footer-ru__txt(v-html="`${ $t('user.email') }: `")
					a.footer-ru__nav-link(href="mailto:mail@paywall.pw") mail@paywall.pw
				span.color-grey.footer-ru__txt(v-html="$t('contacts.desc')")
		.footer-ru__nav.d-block.d-lg-none.footer-ru__details
			span.d-block.footer-ru__txt.footer-ru__title {{ $t('bankDetails.title') }}
			span.color-grey.footer-ru__txt(v-html="$t('bankDetails.desc')")

		.footer-ru__bottom
			span {{ `© 2020–${currentYear} Paywall` }}
			a(:href="islocaleRuSystemAndIp ? 'https://docs.paywall.pw/terms' : 'https://docs.paywall.pw/terms-and-privacy'" target="_blank") {{ $t('landingNew.footer.terms') }}
			a(:href="islocaleRuSystemAndIp ? ' https://docs.paywall.pw/terms/privacy' : 'https://docs.paywall.pw/terms-and-privacy/privacy-policy'" target="_blank") {{ $t('landingNew.footer.privacy') }}

</template>

<script>
import { socialsRu } from '@/constants/config';

export default {
    name: 'FooterRuDomain',
    computed: {
        socialsRu() {
            return socialsRu.apply(this);
        },
        currentYear() {
            return new Date().getFullYear();
        },
    },
    methods: {
        goToMonetization() {
            if (this.$route.name != 'landingContent') {
                this.$router.push({ name: 'landingContent' });
                window.scrollTo({ top: 0, behavior: 'instant' });
            }
        },
        goToNeurobot() {
            if (this.$route.name != 'landingNeurobot') {
                this.$router.push({ name: 'landingNeurobot' });
                window.scrollTo({ top: 0, behavior: 'instant' });
            }
        },
        goToAboutUs() {
            if (this.$route.name != 'landingAbout') {
                this.$router.push({ name: 'landingAbout' });
                window.scrollTo({ top: 0, behavior: 'instant' });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.footer-ru {
    max-width: 1246px;
    margin: 0 auto;
    padding: 80px 16px 20px;

    @media (max-width: 767.98px) {
        padding-left: 12.5px;
        padding-right: 12.5px;
        padding-top: 65px;
    }

    @media (max-width: 575.98px) {
        padding-top: 49px;
    }

    &__top {
        display: flex;
        justify-content: space-between;

        @media (max-width: 767.98px) {
            flex-wrap: wrap;
        }
    }

    &__logo {
        display: block;
        width: 144.5px;
        height: auto;
        margin-bottom: 37px;

        @media (max-width: 767.98px) {
            width: 92px;
        }

        @media (max-width: 575.98px) {
            margin-bottom: 32px;
        }

        &-img {
            width: 100%;
            height: auto;
        }
    }

    &__first-col {
        width: 12.11%;

        @media (max-width: 991.98px) {
            width: 20%;
        }

        @media (max-width: 767.98px) {
            width: 100%;
        }
    }

    &__menu {
        width: 15.24%;

        @media (max-width: 1199.98px) {
            width: 17%;
        }

        @media (max-width: 991.98px) {
            width: 23%;
        }

        @media (max-width: 767.98px) {
            width: 30%;
        }

        @media (max-width: 575.98px) {
            width: 45%;
        }

        .footer-ru__nav-link {
            margin-bottom: 14px;

            @media (max-width: 575.98px) {
                margin-bottom: 7px;
            }
        }
    }

    &__title {
        margin-bottom: 15px;
    }

    &__details,
    &__contacts {
        width: 28.67%;

        @media (max-width: 1199.98px) {
            width: 25%;
        }
    }

    &__details {
        @media (max-width: 991.98px) {
            width: 100%;
            margin-top: 25px;
        }

        @media (max-width: 767.98px) {
            margin-top: 20px;
        }

        @media (max-width: 575.98px) {
            margin-top: 15px;
        }
    }

    &__contacts {
        @media (max-width: 991.98px) {
            width: 40%;
        }

        @media (max-width: 767.98px) {
            width: 60%;
        }

        @media (max-width: 575.98px) {
            width: 55%;
        }
    }

    &__item {
        display: block;
        margin-bottom: 10px;
    }

    &__socials {
        display: flex;
        flex-wrap: wrap;
        width: calc(100% + 17px);

        @media (max-width: 767.98px) {
            width: 100%;
        }

        @media (max-width: 575.98px) {
            margin-bottom: 8px;
        }

        &-link {
            display: block;
            width: 24px;
            height: 24px;
            transition: transform var(--la-time);
            margin-right: 17px;
            border-radius: 50%;
            margin-bottom: 23px;

            @media (max-width: 767.98px) {
                width: 30px;
                height: 30px;
            }

            @media (max-width: 575.98px) {
                margin-right: 15px;
            }

            &:hover,
            &:active {
                transform: scale(1.05);
            }

            &:last-child {
                margin-right: 0;
            }
        }

        &-image {
            width: 100%;
            height: 100%;
        }
    }

    &__nav-link,
    &__txt {
        font-size: 14px;
        line-height: 21px;

        @media (max-width: 575.98px) {
            font-size: 12px;
            line-height: 18px;
        }
    }

    &__nav-link {
        display: inline-block;
        margin-bottom: 13px;
        color: var(--lc-black);
        transition: var(--la-time);

        &:hover,
        &:active {
            opacity: 0.5;
            color: var(--lc-black) !important;
        }
    }

    &__bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 35px;
        padding-bottom: 27px;
        border-top: 1px solid var(--lc-grey-5);
        margin-top: 17.5px;

        @media (max-width: 767.98px) {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            padding-top: 18px;
            padding-bottom: 19px;
        }

        @media (max-width: 575.98px) {
            padding-top: 1px;
            padding-bottom: 10px;
        }

        span,
        a {
            font-size: 14px;
            color: var(--lc-ebony);
            line-height: 150%;
            opacity: 0.3;
            font-weight: 500;

            @media (max-width: 767.98px) {
                margin-top: 20px;
                font-size: 13px;
                width: 100%;
                text-align: center;
            }

            @media (max-width: 575.98px) {
                font-size: 12px;
            }
        }

        a {
            transition: var(--la-time);

            &:hover,
            &:active {
                opacity: 0.5;
                color: var(--lc-ebony) !important;
            }
        }
    }
}
</style>
